<!-- 班级学生排名 -->
<template>
  <div class="classStudent_box_css">
    <el-carousel height="21rem" :interval="5000" arrow="never">
      <el-carousel-item class="carousel-item-css" v-for="num in carouselNum" :key="num">
        <div v-for="(itb, idx) in listArr.slice((num - 1) * 2, num * 2)" :key="idx">
          <div class="className-box-css">{{ itb.className }}</div>
          <div class="studentList-box-css">
            <div class="studentList-item-css" v-for="(item, index) in itb.studentList" :key="index">
              <div class="ranking_index_css">
                <img v-if="index == 0" src="@/assets/largeScreen/diyiming.png" />
                <img v-else-if="index == 1" src="@/assets/largeScreen/dierming_1.png" />
                <img v-else-if="index == 2" src="@/assets/largeScreen/disanming_1.png" />
                <div v-else>{{ index + 1 }}</div>
              </div>
              <img class="ranking_image_css" :src="item.photo" @error="imgError((num - 1) * 2 + idx, index)" />
              <div class="width_css">
                <div class="ranking_userName_css">{{ item.userName }}</div>
              </div>
              <div class="ranking_zanCount_css">{{ getUnit(item.score) }}</div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import getAva from "./photoList";
export default {
  data() {
    return {
      carouselNum: 0, // 页数
      listArr: [
        {
          className: "2023级1班",
          studentList: [
            { userName: "陈伟人", photo: "", score: "450093" },
            { userName: "黄逸平", photo: "", score: "4591" },
            { userName: "赵琼名", photo: "", score: "4575" },
            { userName: "陈美平", photo: "", score: "4523" },
            { userName: "梁家豪", photo: "", score: "4510" },
            { userName: "许梦恩", photo: "", score: "4435" },
            { userName: "袁宗翰", photo: "", score: "4425" },
            { userName: "施秋萍", photo: "", score: "4402" },
          ],
        },
        {
          className: "2023级2班",
          studentList: [
            { userName: "陈伟人", photo: "", score: "4593" },
            { userName: "黄逸平", photo: "", score: "4591" },
            { userName: "赵琼名", photo: "", score: "4575" },
            { userName: "陈美平", photo: "", score: "4523" },
            { userName: "梁家豪", photo: "", score: "4510" },
            { userName: "许梦恩", photo: "", score: "4435" },
            { userName: "袁宗翰", photo: "", score: "4425" },
            { userName: "施秋萍", photo: "", score: "4402" },
          ],
        },
        {
          className: "2023级3班",
          studentList: [
            { userName: "陈伟人", photo: "", score: "4593" },
            { userName: "黄逸平", photo: "", score: "4591" },
            { userName: "赵琼名", photo: "", score: "4575" },
            { userName: "陈美平", photo: "", score: "4523" },
            { userName: "梁家豪", photo: "", score: "4510" },
            { userName: "许梦恩", photo: "", score: "4435" },
            { userName: "袁宗翰", photo: "", score: "4425" },
            { userName: "施秋萍", photo: "", score: "4402" },
          ],
        },
        {
          className: "2023级4班",
          studentList: [
            { userName: "陈伟人", photo: "", score: "4593" },
            { userName: "黄逸平", photo: "", score: "4591" },
            { userName: "赵琼名", photo: "", score: "4575" },
            { userName: "陈美平", photo: "", score: "4523" },
            { userName: "梁家豪", photo: "", score: "4510" },
            { userName: "许梦恩", photo: "", score: "4435" },
            { userName: "袁宗翰", photo: "", score: "4425" },
            { userName: "施秋萍", photo: "", score: "4402" },
          ],
        },
        {
          className: "2023级5班",
          studentList: [
            { userName: "陈伟人", photo: "", score: "4593" },
            { userName: "黄逸平", photo: "", score: "4591" },
            { userName: "赵琼名", photo: "", score: "4575" },
            { userName: "陈美平", photo: "", score: "4523" },
            { userName: "梁家豪", photo: "", score: "4510" },
            { userName: "许梦恩", photo: "", score: "4435" },
            { userName: "袁宗翰", photo: "", score: "4425" },
            { userName: "施秋萍", photo: "", score: "4402" },
          ],
        },
      ],
    };
  },
  created() {
    this.carouselNum = Math.ceil(this.listArr.length / 2);
  },
  methods: {
    getUnit(e) {
      let size = Number(e);
      if (size < 10000) {
        return size;
      } else if (size < 10000 * 1000) {
        return (size / 10000).toFixed(2) + "万";
      } else {
        return (size / (10000 * 1000)).toFixed(2) + "千万";
      }
    },
    imgError(idx, index) {
      this.listArr[idx].studentList[index].photo = getAva();
    },
  },
};
</script>
<style scoped>
.classStudent_box_css {
  width: 450px;
  height: 410px;
  background-color: #ffffff98;
  border-radius: 10px;
  overflow: hidden;
}
/deep/ .el-carousel__button {
  width: 10px;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 0;
  left: 0 !important;
  right: 0 !important;
  transform: none;
  text-align: center;
}
.carousel-item-css {
  padding: 8px 14px 4px;
  box-sizing: border-box;
}
.carousel-item-css>div {
  margin-bottom: 6px;
}
.className-box-css {
  text-align: center;
  margin-bottom: 3px;
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
}
.studentList-box-css {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.studentList-item-css {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 48%;
  flex-shrink: 0;
}
.ranking_image_css {
  width: 35px;
  height: 35px;
  border-radius: 1000px;
  flex-shrink: 0;
  margin-right: 10px;
  object-fit: cover;
}
.ranking_index_css {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  color: #a0a0a098;
  font-weight: bold;
}
.ranking_index_css > img {
  width: 30px;
  height: 30px;
}
.ranking_userName_css {
  /* font-weight: bold; */
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_zanCount_css {
  /* font-weight: bold; */
  font-size: 16px;
  flex-shrink: 0;
  margin-left: 10px;
}
.width_css {
  flex-grow: 1;
}
</style>
