<!-- 班级人数 -->
<template>
  <div>
    <shell title="班级男女人数">
      <div id="ClassNumber" class="classNumberCss"></div>
    </shell>
  </div>
</template>

<script>
import * as echarts from "echarts";
import shell from "./shell.vue";
let classNumberChart = null;
export default {
  components: {
    shell,
  },
  data() {
    return {};
  },
  mounted() {
    this.ClassNumber();
  },
  methods: {
    async ClassNumber() {
      if (classNumberChart) {
        classNumberChart.dispose();
      }

      classNumberChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      classNumberChart = echarts.init(document.getElementById("ClassNumber"));
      const dataX = ["2023级1班", "2023级2班", "2023级3班", "2023级4班", "2023级5班", "2023级6班", "2023级7班", "2023级8班", "2023级9班", "2023级10班", "2023级11班", "2023级12班", "2023级13班", "2023级14班", "2023级15班"];
      // 绘制图表
      classNumberChart.setOption({
        legend: {
          top: 0,
          right: 10,
          icon: "circle",
          data: ["男生", "女生"],
          textStyle: {
            color: "#333",
            fontSize: 14,
            fontWeight: 600,
          },
        },
        grid: { left: 25, top: 40,bottom: 5, right: 10, containLabel: true },
        xAxis: {
          type: "category",
          data: dataX,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: { color: "#ccc" },
          },
          axisLabel: {
            fontSize: 12,
            color: "#666",
            interval: 0,
            formatter: function(value) {
              var ret = ""; // 拼接加\n返回的类目项
              var maxLength = 5; // 每项显示文字个数
              var valLength = value.length; // X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); // 类目项需要换行的行数
              if (rowN > 1) {
                // 如果类目项的文字大于3,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; // 每次截取的字符串
                  var start = i * maxLength; // 开始截取的位置
                  var end = start + maxLength; // 结束截取的位置
                  if (i === rowN - 1) {
                    temp = value.substring(start, end);
                  } else {
                    temp = value.substring(start, end) + "\n";
                  }
                  ret += temp; // 凭借最终的字符串
                }
                return ret;
              } else {
                return value;
              }
            },
          },
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: { color: "#ccc" },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: "#666",
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ccc",
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
          textStyle: { color: "#424242" },
          renderMode: "html",
          className: "tooltip",
          order: "seriesDesc",
        },
        series: [
        {
            name: "男生",
            type: "bar",
            stack: "total", // ! 多条数据总计 => 堆叠
            barWidth: 20,
            color: "#52A8FF",
            itemStyle: { borderRadius: 0 },
            data: [32, 28, 23, 31, 28, 29, 31, 32, 28, 23, 31, 28, 29, 31, 31],
          },{
            name: "女生",
            type: "bar",
            stack: "total", // ! 多条数据总计 => 堆叠
            barWidth: 20,
            color: "#f995ad",
            itemStyle: { borderRadius: [12, 12, 0, 0] },
            data: [23, 31, 28, 29, 31, 32, 28, 23, 31, 28, 29, 31, 31, 32, 28],
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.classNumberCss {
  width: 780px;
  height: 215px;
  margin-top: -35px;
}
</style>
