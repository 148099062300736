<template>
  <div class="shell_box_css">
    <div class="title_box_css">
      {{ title }}
    </div>
    <div>
        <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.shell_box_css {
  background-color: #ffffff98;
  border-radius: 10px;
  overflow: hidden;
}
.title_box_css {
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
  padding: 10px 13px 5px;
}
</style>
