<!-- 教师排名 -->
<template>
  <div>
    <shell title="年级教师榜">
      <div id="TeacherRanking" class="teacherRankingCss"></div>
    </shell>
  </div>
</template>

<script>
import * as echarts from "echarts";
let teacherRankingChart = null;
import shell from "./shell.vue";
export default {
  components: {
    shell,
  },
  data() {
    return {};
  },
  mounted() {
    this.TeacherRanking();
  },
  methods: {
    async TeacherRanking() {
      if (teacherRankingChart) {
        teacherRankingChart.dispose();
      }

      teacherRankingChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      teacherRankingChart = echarts.init(document.getElementById("TeacherRanking"));
      // 绘制图表
      teacherRankingChart.setOption({
        tooltip: { show: false },
        grid: { left: 5, top: 0, bottom: 0, right: 50, containLabel: true },
        xAxis: {
          type: "value",
          boundaryGap: false,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
          max: 15023, // 使用数组最大值，否则显示会有问题
        },
        yAxis: {
          type: "category",
          data: ["王小明", "陈美", "赵琼发名", "黄逸平", "王小明", "陈美平", "赵琼名", "黄逸平", "赵琼名", "黄逸平"],
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { fontSize: 16, color: "#333", margin: 10, padding: 0 },
          inverse: true,
        },
        series: [
          {
            name: "数量",
            type: "bar",
            itemStyle: {
              borderRadius: 100,
              color: function(params) {
                // 定义一个颜色集合
                let colorList = ["#52A8FF", "#00B389", "#FFA940", "#FF5A57", "#29EFC4", "#F8AEA4", "#FFC53D", "#009982", "#C099FC", "#F5855F"];
                return colorList[params.dataIndex];
              },
            },
            barMaxWidth: 16,
            label: { show: true, position: "insideRight",fontSize: 15, offset: [49, 2], color: "#555" },
            data: [15023, 14823, 14023, 13023, 12923, 12873, 12663, 12433,12023, 11023]
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.teacherRankingCss {
  width: 400px;
  height: 364px;
}
</style>
