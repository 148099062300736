<template>
  <div>
    <shell title="距离下次上课时间还剩">
      <div class="quittingTimeCss">
        <div class="time-box-css">
          5分28秒
        </div>
      </div>
    </shell>
  </div>
</template>

<script>
import shell from "./shell.vue";
export default {
  components: {
    shell,
  },
};
</script>

<style scoped>
.quittingTimeCss {
  width: 400px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-box-css {
  font-size: 80px;
  font-family: "wdzjyhx";
  color: #7457f8;
  font-weight: 600;
  margin-top: -20px;
}
</style>
