<template>
  <div class="notification_box_css">
    <el-carousel height="24rem" ref="carousel" :autoplay="false" arrow="never" trigger="click">
      <el-carousel-item v-for="(item, index) in listArr" :key="index">
        <div class="title-box-css">{{ item.title }}</div>
        <div class="time-box-css">{{ item.time }}</div>
        <div class="content-box-css">
          <div :id="'carousel' + index">
            {{ item.content }}
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselNum: 0,
      listArr: [
        {
          title: "第一学期家长会",
          time: "2023-10-15 15:23",
          content:
            "亲爱的家长朋友们: 您好! 感谢您一直以来对学校工作的关心、理解、信任与支持。新学期已经过去两个多月，您一定对孩子的成长充满了 无限的期许，能够与您携手相伴，共筑孩子美好的明天我们感到非常荣幸与喜悦。为进一步增进家校联系，促进孩子健康、快乐成长，加强学校与家庭教师与家长的沟通、交流，我们诚挚邀请您参加2023年秋季“为爱携手 相伴成长”家长学校授课暨家长会。【时间】2023年11月15日(周三)16:00 【地点】3.5教室 【温提示】 1.三年级下午 15:10 放学，下午托管社团暂停一次。 2.请您在校园周边停车场有序停放好车辆，尽量绿色出行，往返注意安全。亲爱的家长朋友们: 您好! 感谢您一直以来对学校工作的关心、理解、信任与支持。新学期已经过去两个多月，您一定对孩子的成长充满了 无限的期许，能够与您携手相伴，共筑孩子美好的明天我们感到非常荣幸与喜悦。为进一步增进家校联系，促进孩子健康、快乐成长，加强学校与家庭教师与家长的沟通、交流，我们诚挚邀请您参加2023年秋季“为爱携手 相伴成长”家长学校授课暨家长会。【时间】2023年11月15日(周三)16:00 【地点】3.5教室 【温提示】 1.三年级下午 15:10 放学，下午托管社团暂停一次。 2.请您在校园周边停车场有序停放好车辆，尽量绿色出行，往返注意安全。哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈",
        },
        {
          title: "这是一次通知公告标题",
          time: "2023-10-15 15:23",
          content:
            "亲爱的家长朋友们: 您好! 感谢您一直以来对学校工作的关心、理解、信任与支持。新学期已经过去两个多月，您一定对孩子的成长充满了 无限的期许，能够与您携手相伴，共筑孩子美好的明天我们感到非常荣幸与喜悦。为进一步增进家校联系，促进孩子健康、快乐成长，加强学校与家庭教师与家长的沟通、交流，我们诚挚邀请您参加2023年秋季“为爱携手 相伴成长”家长学校授课暨家长会。【时间】2023年11月15日(周三)16:00 【地点】3.5教室 【温提示】 1.三年级下午 15:10 放学，下午托管社团暂停一次。 2.请您在校园周边停车场有序停放好车辆，尽量绿色出行，往返注意安全。亲爱的家长朋友们: 您好! 感谢您一直以来对学校工作的关心、理解、信任与支持。新学期已经过去两个多月，您一定对孩子的成长充满了 无限的期许，能够与您携手相伴，共筑孩子美好的明天我们感到非常荣幸与喜悦。为进一步增进家校联系，促进孩子健康、快乐成长，加强学校与家庭教师与家长的沟通、交流，我们诚挚邀请您参加2023年秋季“为爱携手 相伴成长”家长学校授课暨家长会。【时间】2023年11月15日(周三)16:00 【地点】3.5教室 【温提示】 1.三年级下午 15:10 放学，下午托管社团暂停一次。 2.请您在校园周边停车场有序停放好车辆，尽量绿色出行，往返注意安全。",
        },
        {
          title: "哈啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊哈哈哈哈哈哈",
          time: "2023-10-15 15:23",
          content: "哈啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊哈哈哈哈哈哈哈啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊哈哈哈哈哈哈哈啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊哈哈哈哈哈哈。",
        },
      ],
    };
  },
  mounted() {
    this.getHeight(0);
  },
  methods: {
    // 获取内容高度，处理动画滚动高度及滚动时间，使每个滚动速度保持一致
    getHeight(num) {
      this.$nextTick(() => {
        this.$refs.carousel.setActiveItem(num);
        let hei = document.getElementById("carousel" + num).offsetHeight;
        if (hei < 425) {
          setTimeout(() => {
            this.getHeight((num + 1) % this.listArr.length);
          }, 5000);
        } else {
          let heiRem = (hei - 300) / 20;
          const notificationRowup = `@keyframes notificationRowup {
            0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            100% {
                -webkit-transform: translate3d(0, -${heiRem}rem, 0);
                transform: translate3d(0, -${heiRem}rem, 0);
            }
            }`;
          const style = document.createElement("style");
          style.setAttribute("type", "text/css");
          document.head.appendChild(style);
          style.sheet.insertRule(notificationRowup, 0);
          setTimeout(() => {
            document.getElementById("carousel" + num).style.animation = `${(heiRem / 3).toFixed(1)}s notificationRowup linear`;
          }, 3000);
          setTimeout(() => {
            document.getElementById("carousel" + num).removeAttribute("style");
            this.getHeight((num + 1) % this.listArr.length);
          }, (Number((heiRem / 3).toFixed(1)) + 2.9) * 1000);
        }
      });
    },
  },
};
</script>

<style scoped>
/deep/ .el-carousel__button {
  width: 10px;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 0;
  left: 0 !important;
  right: 0 !important;
  transform: none;
  text-align: center;
}
.notification_box_css {
  width: 400px;
  height: 470px;
  background-color: #ffffff98;
  border-radius: 10px;
  overflow: hidden;
}
.title-box-css {
  text-align: center;
  padding: 8px 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
}
.time-box-css {
  text-align: center;
  font-size: 15px;
  color: #33333371;
  margin-top: -5px;
}
.content-box-css {
  font-size: 16px;
  color: #333333c5;
  padding: 0 15px;
  height: 425px;
  overflow: hidden;
}
.content-box-css > div {
  position: relative;
}
</style>
