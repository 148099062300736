const photoList = [
  'https://img.touxiangwu.com/zb_users/upload/2022/12/202212081670485805715178.jpg',
  'https://img.touxiangwu.com/zb_users/upload/2022/12/202212081670485804704694.jpg',
  'https://img.touxiangwu.com/zb_users/upload/2022/12/202212081670485805511588.jpg',
  'https://img.touxiangwu.com/zb_users/upload/2022/12/202212081670485805593285.jpg',
  'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2F4d40b566-1f0a-4f8d-bc97-c513df8775b3%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1702784739&t=4ee2ff9db818614237c01f685d606c6a',
  'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2F1185bef8-b7da-4d1a-a4a2-4d4bb433214e%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1702784739&t=ea0106d3cbacc1a13a126d63ca8b4166',
]

//补填数据中的头像显示 参数1:接口中数组 参数2:要处理的显示字段(默认photo)
const fillPhoto = (dataArr, showStr = 'photo') => {
  // console.log('填充数据', dataArr, showStr)
  let oldArr = JSON.parse(JSON.stringify(dataArr))
  if (oldArr.length > 0) {
    let numIndex = -1
    let newArr = oldArr.map((obj, idx) => {
      if (!obj[showStr]) {
        if (numIndex == photoList.length - 1) {
          numIndex = 0
        } else {
          numIndex++
        }
        obj[showStr] = photoList[numIndex]
      }
      return obj
    })
    return newArr
  } else {
    return []
  }
}

//获取随机图片
const getAva = () => {
  let ab = Math.round(Math.random() * (photoList.length - 1))
  return photoList[ab]
}

export { fillPhoto }
export default getAva
