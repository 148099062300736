<!-- 班级分数排名 -->
<template>
  <div>
    <shell title="本学期班级得分">
      <div id="ClassRanking" class="classRankingCss"></div>
    </shell>
  </div>
</template>

<script>
import * as echarts from "echarts";
import shell from "./shell.vue";
let classRankingChart = null;
export default {
  components: {
    shell,
  },
  data() {
    return {};
  },
  mounted() {
    this.ClassRanking();
  },
  methods: {
    async ClassRanking() {
      if (classRankingChart) {
        classRankingChart.dispose();
      }

      classRankingChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      classRankingChart = echarts.init(document.getElementById("ClassRanking"));
      const dataX = ["2023级1班", "2023级2班", "2023级3班", "2023级4班", "2023级5班", "2023级6班", "2023级7班", "2023级8班", "2023级9班", "2023级10班", "2023级11班", "2023级12班", "2023级13班", "2023级14班", "2023级15班"];
      const dataY = [9753, 15230, 12459, 18963, 13220, 12489, 10258, 20361, 10862, 13456, 12489, 10258, 20361, 10862, 13456];
      // 绘制图表
      classRankingChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function(params) {
            return `${params[0].marker} ${params[0].name}：${params[0].value}分`;
          },
        },
        grid: {
          left: 50,
          right: 10,
          top: 8,
          bottom: 35,
        },
        xAxis: [
          {
            type: "category",
            data: dataX,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#ccc" },
            },
            axisLabel: {
              fontSize: 12,
              color: "#666",
              interval: 0,
              formatter: function(value) {
                var ret = ""; // 拼接加\n返回的类目项
                var maxLength = 5; // 每项显示文字个数
                var valLength = value.length; // X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); // 类目项需要换行的行数
                if (rowN > 1) {
                  // 如果类目项的文字大于3,
                  for (var i = 0; i < rowN; i++) {
                    var temp = ""; // 每次截取的字符串
                    var start = i * maxLength; // 开始截取的位置
                    var end = start + maxLength; // 结束截取的位置
                    if (i === rowN - 1) {
                      temp = value.substring(start, end);
                    } else {
                      temp = value.substring(start, end) + "\n";
                    }
                    ret += temp; // 凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
            },
          },
        ],
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: { color: "#ccc" },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: "#666",
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ccc",
            },
          },
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              borderRadius: [12, 12, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#ADDAFF",
                  opacity: 0.98,
                },
                {
                  offset: 0.8,
                  color: "#74A3FF",
                  opacity: 0.85,
                },
                {
                  offset: 0.5,
                  color: "#74A3FF",
                  opacity: 0.85,
                },
                {
                  offset: 0.2,
                  color: "#74A3FF",
                  opacity: 0.85,
                },
                {
                  offset: 0,
                  color: "#ADDAFF",
                  opacity: 0.95,
                },
              ]),
            },
            barWidth: "20",
            label: {
              show: true,
              color: "#74A2FF",
              position: "outside",
            },
            data: dataY,
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.classRankingCss {
  width: 780px;
  height: 180px;
}
</style>
