<template>
  <div class="ps_pages_Css">
    <!-- 德智体美劳 -->
    <div class="intellectual-box-css">
      <intellectual />
    </div>
    <!-- 班级学生排名 -->
    <div class="classStudent-box-css">
      <classStudent />
    </div>
    <!-- 通知公告 -->
    <div class="notification-box-css">
      <notification />
    </div>
    <!-- 班级活动 -->
    <div class="classActivity-box-css">
      <classActivity />
    </div>
    <!-- 教师排名 -->
    <div class="teacherRanking-box-css">
      <teacherRanking />
    </div>
    <!-- 班级总得分 -->
    <div class="classRanking-box-css">
      <classRanking />
    </div>
    <!-- 班级人数 -->
    <div class="classNumber-box-css">
      <classNumber />
    </div>
    <!-- 当前请假人员 -->
    <div class="askForLeave-box-css">
      <askForLeave />
    </div>
    <!-- 放学时间 -->
    <div class="quittingTime-box-css">
      <quittingTime />
    </div>
  </div>
</template>

<script>
import intellectual from './components/intellectual.vue'
import classStudent from './components/classStudent.vue'
import classActivity from './components/classActivity.vue'
import notification from './components/notification.vue'
import teacherRanking from './components/teacherRanking.vue'
import classRanking from './components/classRanking.vue'
import quittingTime from './components/quittingTime.vue'
import askForLeave from './components/askForLeave.vue'
import classNumber from './components/classNumber.vue'
export default {
  components: {
    intellectual,
    classStudent,
    classActivity,
    notification,
    teacherRanking,
    classRanking,
    quittingTime,
    askForLeave,
    classNumber,
  },
  data() {
    return {}
  },
}
</script>
<style>
@font-face {
  font-family: 'crjk';
  src: url(../../assets/largeScreen/54115de07a5907a9b1574992473.ttf);
}
@font-face {
  font-family: 'wdzjyhx';
  src: url(../../assets/largeScreen/81425e6332500e3301583559248.ttf);
}
</style>
<style scoped>
.ps_pages_Css {
  background-image: url('../../assets/largeScreen/5d203aeceeb331562393324642.jpg');
  background-size: cover;
  /* background-color: #ecf1ff; */
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.intellectual-box-css {
  position: absolute;
  top: 20px;
  left: 20px;
}
.classStudent-box-css {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.classActivity-box-css {
  position: absolute;
  bottom: 20px;
  right: 440px;
}
.notification-box-css {
  position: absolute;
  top: 20px;
  right: 20px;
}
.teacherRanking-box-css {
  position: absolute;
  bottom: 20px;
  left: 490px;
}
.classRanking-box-css {
  position: absolute;
  top: 20px;
  left: 700px;
}
.classNumber-box-css {
  position: absolute;
  top: 265px;
  left: 700px;
}
.quittingTime-box-css {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.askForLeave-box-css {
  position: absolute;
  bottom: 230px;
  right: 20px;
}
</style>
