<!-- 德智体美劳 -->
<template>
  <div>
    <shell title="德智体美劳近期评价数据">
      <div id="Intellectual" class="intellectualCss"></div>
    </shell>
  </div>
</template>

<script>
import * as echarts from "echarts";
import shell from "./shell.vue";
let intellectualChart = null;
export default {
  components: {
    shell,
  },
  data() {
    return {};
  },
  mounted() {
    this.Intellectual();
  },
  methods: {
    async Intellectual() {
      if (intellectualChart) {
        intellectualChart.dispose();
      }

      intellectualChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      intellectualChart = echarts.init(document.getElementById("Intellectual"));
      let rq = ['第四周','第五周', '第六周','第七周', '第八周', '第九周', '第十周'];
      let seriesArr = [];
      let list = [
        {
          name: "德",
          children: [1705, 1075, 1505, 1550, 1405, 1500, 1400, 1230, 1365, 1480],
        },
        {
          name: "智",
          children: [1150, 1150, 1140, 1410, 1235, 1040, 1320, 1350, 1310, 1380],
        },
        {
          name: "体",
          children: [1100, 1200, 1300, 1200, 1280, 1290, 1300, 1288, 1488, 1399],
        },{
          name: "美",
          children: [1250, 1350, 1440, 1240, 1035, 1340, 1130, 1235, 1430, 1230],
        },
        {
          name: "劳",
          children: [1075, 1075, 1255, 1255, 1145, 1450, 1140, 1330, 1135, 1440],
        },
      ];
      let colorArr = ["0, 62, 246", "0, 193, 142", "253, 148, 67","231, 18, 188", "231, 73, 18"];
      list.forEach((val, index) => {
        seriesArr.push({
          name: val.name,
          type: "line",
          symbolSize: 6,
          data: val.children,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: `rgba(${colorArr[index]},.2)`,
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0)",
                  },
                ],
                false
              ),
            },
          },
          itemStyle: {
            normal: {
              color: `rgb(${colorArr[index]})`,
            },
          },
          lineStyle: {
            normal: {
              width: 2,
              shadowColor: `rgba(${colorArr[index]}, .2)`,
              shadowBlur: 4,
              shadowOffsetY: 25,
            },
          },
        });
      });
      // 绘制图表
      intellectualChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#ddd",
            },
          },
          backgroundColor: "rgba(255,255,255,1)",
        },
        legend: {
          right: 10,
          top: 0,
          textStyle: {
            color: "#000",
            fontSize: 14,
            fontWeight: 600,
          },
          data: list.map((val) => {
            return val.name;
          }),
        },
        grid: {
          left: 10,
          right: 20,
          bottom: 5,
          top:40,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: rq,
          boundaryGap: false,
          splitLine: {
            show: true,
            interval: "auto",
            lineStyle: {
              type: "dashed",
              color: ["#ccc"],
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 12,
              color: "#666",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#ccc"],
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                fontSize: 12,
                color: "#ccc",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "#666",
              },
            },
            min: 1000, // 取最小数，往下取100的整数。例如 1059，取1000
          },
        ],
        series: seriesArr,
      });
    },
  },
};
</script>

<style scoped>
.intellectualCss {
  width: 660px;
  height: 460px;
  margin-top: -36px;
}
</style>
