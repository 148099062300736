<!-- 班级活动图片 -->
<template>
  <div class="classActivity_box_css">
    <div class="title_box_css">参观黑龙江大雪</div>
    <el-carousel height="21.1rem" arrow="never" indicator-position="none">
      <el-carousel-item v-for="(item, index) in listArr" :key="index">
        <img class="carousel-bg-mg-css" :src="item" />
        <img class="carousel-img-css" :src="item" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import shell from "./shell.vue";
export default {
  components: {
    shell,
  },
  data() {
    return {
      listArr: [
        "https://img1.baidu.com/it/u=1500716295,3279382336&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800",
        "https://img0.baidu.com/it/u=666517787,2620707380&fm=253&fmt=auto&app=120&f=JPEG?w=1140&h=641",
        "https://img2.baidu.com/it/u=53888956,1179308955&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750",
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
<style>
@font-face {
  font-family: "wryh";
  src: url(../../../assets/largeScreen/20190301111201.ttf);
}
</style>
<style scoped>
.classActivity_box_css {
  width: 570px;
  height: 410px;
  background-color: #ffffff98;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.carousel-img-css {
  width: 570px;
  height: 410px;
  object-fit: contain;
  position: absolute;
  z-index: 99;
}
.carousel-bg-mg-css {
  width: 570px;
  height: 410px;
  object-fit: cover;
  opacity: 0.8;
  position: absolute;
  z-index: 1;
  filter: blur(5px);
}
.title_box_css {
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
  padding: 10px 13px 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #f1f1f1ad;
}
</style>
